import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import SocialWrapper from "../../components/SocialWrapper";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs.js";
import Banner from "../../components/Banner";
import EmailSignupSimplePlugin from "../../components/EmailSignupSimplePlugin";
import { experienceYears } from "../../components/Helpers";

const StyledMiddle = styled.div`
  margin: 0vmin 1vmin;
  .container {
    margin: 7vmin 0vmin;
    display: flex;
  }

  h1 {
    padding: 0;
    margin: 0rem 0rem 1rem 0rem;
  }

  .content {
    width: 60%;
    margin-right: 1rem;
  }

  .image-wrapper {
    margin: 0rem 0rem auto 0rem;
    width: 30%;

    & .gatsby-image-wrapper {
      box-shadow: 0px 0px 3px var(--foreground);
      margin-bottom: 1rem;
    }
  }
  @media all and (max-width: 540px) {
    justify-content: center;
    .container {
      flex-direction: column-reverse;
    }

    .content {
      width: 100%;
      margin-right: none;
    }

    .image-wrapper {
      margin: 1rem auto 4rem auto;
      width: 60%;
    }
  }
`;

const StyledSecondaryContent = styled.div`
  grid-area: secondary;
  background: orange;
  display: flex;

  .card {
    border-top: 1px solid var(--foreground);
    &:first-child {
      border-right: 1px solid var(--foreground);
    }
  }

  @media all and (max-width: 700px) {
    flex-direction: column;
  }
`;

// Breadcrumb data

const breadCrumbLevels = {
    Koti: "/fi",
    "Tietoa minusta": "/fi/tietoa"
  };
  
// Hreflang data

const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/about"
);

  const About = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO title="Tietoa Matthias Kupperschmidtistä" lang="fi" alternateLangs={alternateLangs} />
        <MainContent maxWidth="700">
          <StyledMiddle>
            <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
            <div className="container">
              <div className="content">
                <H as="h1">Hei, olen Matthias</H>
                <p>Optimoin verkkosivustoja Googlen hakutuloksia varten ja toteutan analytiikka-asetuksia.</p>
<p>
  Olen saksalainen, asun Kööpenhaminassa, Tanskassa. Olen työskennellyt {experienceYears} vuotta useissa digitaalisissa toimistoissa ennen kuin aloitin
  työskentelyn <Link to="/fi/google-analytics-freelancerina">freelancerina Google Analyticsin</Link> ja SEO:n parissa.
</p>
<p>
  Useiden persoonallisuustestien mukaan olen erittäin analyyttinen, rationaalinen ja järjestelmällinen, samalla kun olen
  tavoitekeskeinen ja pragmaattinen.
</p>
<H as="h2">Minun tarinani</H>
<p>
  14-vuotiaana rakensin ensimmäisen verkkosivuni elokuvien vaihtamista varten koulunpihalla. Se oli paras tapa näyttää
  elokuvakokoelmani luokkatovereilleni. Muistan luoneeni ensimmäiset HTML-tiedostot Microsoft Wordilla ja myöhemmin
  editorilla nimeltä "Phase5".
</p>
<p>
  Myöhemmin rakensin oman verkkokauppani pöytäjalkapallolle (tai "fooseball" kuten me sitä kutsumme), nimeltään
  "Kickerkult". Olin 18-vuotias ja aloitteleva, wannabe-ammattilainen pöytäjalkapalloilija, joten se oli hyvä yhdistelmä
  kahdesta kiinnostuksestani.
  <br /> Tuolloin pelasin monia turnauksia Berliinissä ja ympäri Saksaa. Samalla hallinnoin verkkokauppaani, opin lähettämään
  sähköpostiuutiskirjeitä ja aloitin SEO:n tekemisen.
  <br />
  Lopulta kiinnostuin verkosta ja sen mahdollisuuksista ja halusin keskittyä uraani siihen.
</p>
<p>
  Tänään autan erikokoisia yrityksiä pääsemään Googlen hakutulosten kärkeen ja seuraamaan käyttäjäkäyttäytymistään - riippumatta siitä, mistä päin maailmaa yritys on.
</p>
<p>
  Lisäksi kirjoitan SEO- ja analytiikka-aiheista <Link to="/fi/blogi">blogissani</Link>.
</p>
<H as="h2">
  Mikä kaupunki on seuraava Silicon Valley?
  <br />
  Sinun!
</H>
<p>
  Uskon vahvasti, että suurkaupunkeihin ei tarvitse muuttaa saavuttaakseen loistavan uran. Internetin kautta nykyaikaiset
  yritykset, ammattilaiset ja samanhenkiset ihmiset löytävät toisensa ja työskentelevät yhdessä - maantieteellisestä etäisyydestä riippumatta.
</p>
</div>
<div className="image-wrapper">
  <Img clickable="false" src="about/matthias-kupperschmidt-about-me-portrait.jpeg" alt="matthias kupperschmidt" />
  <SocialWrapper align="center" twitter="mattzipan" linkedin="matthiaskupperschmidt" github="mattzipan" />
</div>
</div>
</StyledMiddle>
</MainContent>
<StyledSecondaryContent>
  <Banner>
    <div className="banner__header serif headline">Pysytään yhteydessä!</div>
    <div className="serif">
      Älä huoli, lähetän sähköpostia hyvin harvoin. Tilaa tästä, jotta saat varmasti tiedon, kun minulla on jotain tärkeää kerrottavaa.
    </div>
    <div className="emailwrapper">
      <EmailSignupSimplePlugin />
    </div>
  </Banner>
  <Banner>
    {/* <div className="banner__header serif headline">
          “Tarvitsen apua SEO:ssa tai analytiikassa”
        </div>
        <div className="serif">
          Älä huoli, lähetän sähköpostia hyvin harvoin. Tilaa tästä, jotta saat varmasti
          tiedon, kun minulla on jotain tärkeää kerrottavaa.
        </div> */}
  </Banner>
</StyledSecondaryContent>
</React.Fragment>

</Layout>
);

export default React.memo(About);
